<template>
  <div>
    <c-tab
      :dense="true"
      type="tabcard"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param.sync="param"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'project-common-data-manage',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
    contentHeight: null, 
  },
  data() {
    return {
      editable: true,
      tab: 'comdata1',
      tabItems: [
        { name: 'comdata1', icon: 'settings', label: '작업허가서 가스항목', component: () => import(`${'./workGasItem.vue'}`) },
        { name: 'comdata2', icon: 'settings', label: '작업허가서 안전조치사항', component: () => import(`${'./workCheckItem.vue'}`) },
        { name: 'comdata3', icon: 'settings', label: '안전순찰 점검항목', component: () => import(`${'./safetyCheckItem.vue'}`) },
        { name: 'comdata4', icon: 'settings', label: '기계/장비 유형별 점검항목', component: () => import(`${'./machineryTypeInsManage'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
    },
  }
};
</script>
